import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackToWork from "../components/backtowork"

const InternalPage = () => (
  <Layout>
    <SEO title="Lake Huron Lodge" />
    <main className="internal">
      <BackToWork />
      <div className="content">
        <div className="full">
          <h1>UI Redesign: Lake Huron Lodge</h1>
          <p>To sell yourself as a place of hospitality and tourism, there are two key things you need to highlight: clear and concise features of where your guests will be staying, and beautiful photography. In a previous iteration of <a href="https://www.lakehuronlodge.com/">lakehuronlodge.com</a>, the site was in need of a refresh to accomplish both of these.</p>
          <h2>Home Page</h2>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647398/hireallisonokeefe/lake-huron-lodge/lhl_01_rlceyr.png" alt="" />
            <figcaption>Above: comparison of home page, 2015 vs. current.</figcaption>
          </figure>
          <h3>Issue #1: Romance copy is the main focus above the fold.</h3>
          <p>Solution: Having a bold hero above the romance copy will increase guests interest and reduce bounce rates.</p>
          <h3>Issue #2: Call-to-actions are below the fold.</h3>
          <p>Solution: Moving the CTAs farther up the page gets the customer engaging with the site more quickly, </p>
          <h3>Issue #3: Misuse of space regarding Guest Experience Features.</h3>
          <p>Solution: Tightening up the UI makes the Guest Experience Features look less haphazard, but is still tied to the romance copy (as guests scan the page).</p>
          <h2>Suites Listing Page</h2>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647398/hireallisonokeefe/lake-huron-lodge/lhl_02_harghc.png" alt="" />
            <figcaption>Above: comparison of the Suites Listing page, 2015 vs. current.</figcaption>
          </figure>
          <h3>Issue #1: Small imagery doesn't entice guests to click into a details page.</h3>
          <p>Solution: Enlarge all imagery and arrange in a grid rather than list array. This makes it easier for the guest to properly see (rather than read) what the Suites look like. Uses the space of the site more appropriately.</p>
          <h3>Issue #2: Too much information up front.</h3>
          <p>Solution: Reduce the focus on the amount of suite information given to the guest at this stage in their journey. Also include a CTA for guests who are interested in staying at the Lodge, but are struggling to move forward with making a reservation.</p>
          <h2>Individual Suite Details Page</h2>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647398/hireallisonokeefe/lake-huron-lodge/lhl_03_mxvkmf.png" alt="" />
            <figcaption>Above: comparison of an Individual Suite Detail Page, 2015 vs. current.</figcaption>
          </figure>
          <h3>Issue #1: No clear call-to-action.</h3>
          <p>Solution: CTA attached to suite rates treats the suite more like a product, giving better access to making a reservation. Inclusion of a secondary CTA at the bottom, gives guests a second method of moving forward.</p>
          <h3>Issue #2: Small imagery.</h3>
          <p>Solution: Enlarge all imagery full-width. Gives guests a better view to where they could be staying.</p>
          <h3>Issue #3: Suite features and amenities all blend together, making it more difficult to determine what comes with the suite.</h3>
          <p>Solution: Clear separations of main suite features (maximum guests, number of beds and baths), amenities, and other key suite aspects gives the guest a distinct understanding of what to expect upon arrival.</p>
          <h2>Results</h2>
          <p>As of 2018, <a href="https://www.lakehuronlodge.com/">lakehuronlodge.com</a> was contributing to a 96% lift in guest reservations. Competing websites (with the same information) include Airbnb, VRBO, and TripAdvisor.</p>
          <p><span className="text--bold">Programs used:</span> Photoshop, Wix</p>
        </div>
      </div>
    </main>
  </Layout>
)

export default InternalPage
