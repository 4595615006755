import React from "react"
import { Link } from "gatsby"

function BackToWork() {
  return (
    <div className="back">
      <Link to="/">Back to Work</Link>
    </div>
  )
}

export default BackToWork
