import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <nav className="nav">
    <div className="nav__header">{siteTitle}</div>
    <ul className="nav__links">
      <li className="nav__link"><Link to="/" activeClassName="active">Work</Link></li>
      <li className="nav__link"><Link to="/resume/" activeClassName="active">Resume</Link></li>
    </ul>
  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
